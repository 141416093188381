export const Btn = () => import('../../components/Btn.vue' /* webpackChunkName: "components/btn" */).then(c => wrapFunctional(c.default || c))
export const GridList = () => import('../../components/GridList.vue' /* webpackChunkName: "components/grid-list" */).then(c => wrapFunctional(c.default || c))
export const InputElectionDistrict = () => import('../../components/InputElectionDistrict.vue' /* webpackChunkName: "components/input-election-district" */).then(c => wrapFunctional(c.default || c))
export const JayuLogo = () => import('../../components/JayuLogo.vue' /* webpackChunkName: "components/jayu-logo" */).then(c => wrapFunctional(c.default || c))
export const KakaoMapGeo = () => import('../../components/KakaoMapGeo.vue' /* webpackChunkName: "components/kakao-map-geo" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const Accordion = () => import('../../components/accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const AccordionItem = () => import('../../components/accordionItem.vue' /* webpackChunkName: "components/accordion-item" */).then(c => wrapFunctional(c.default || c))
export const Comment = () => import('../../components/comment.vue' /* webpackChunkName: "components/comment" */).then(c => wrapFunctional(c.default || c))
export const CommentActivity = () => import('../../components/commentActivity.vue' /* webpackChunkName: "components/comment-activity" */).then(c => wrapFunctional(c.default || c))
export const CommentNavigation = () => import('../../components/commentNavigation.vue' /* webpackChunkName: "components/comment-navigation" */).then(c => wrapFunctional(c.default || c))
export const CommentNavigationActivity = () => import('../../components/commentNavigationActivity.vue' /* webpackChunkName: "components/comment-navigation-activity" */).then(c => wrapFunctional(c.default || c))
export const Comments = () => import('../../components/comments.vue' /* webpackChunkName: "components/comments" */).then(c => wrapFunctional(c.default || c))
export const CommentsActivity = () => import('../../components/commentsActivity.vue' /* webpackChunkName: "components/comments-activity" */).then(c => wrapFunctional(c.default || c))
export const Dropdown = () => import('../../components/dropdown.vue' /* webpackChunkName: "components/dropdown" */).then(c => wrapFunctional(c.default || c))
export const Favorites = () => import('../../components/favorites.vue' /* webpackChunkName: "components/favorites" */).then(c => wrapFunctional(c.default || c))
export const Finder = () => import('../../components/finder.vue' /* webpackChunkName: "components/finder" */).then(c => wrapFunctional(c.default || c))
export const Flip = () => import('../../components/flip.vue' /* webpackChunkName: "components/flip" */).then(c => wrapFunctional(c.default || c))
export const HeaderDefault = () => import('../../components/headerDefault.vue' /* webpackChunkName: "components/header-default" */).then(c => wrapFunctional(c.default || c))
export const HeaderType01 = () => import('../../components/headerType01.vue' /* webpackChunkName: "components/header-type01" */).then(c => wrapFunctional(c.default || c))
export const HeaderTypeStuff = () => import('../../components/headerTypeStuff.vue' /* webpackChunkName: "components/header-type-stuff" */).then(c => wrapFunctional(c.default || c))
export const ImageSlider = () => import('../../components/imageSlider.vue' /* webpackChunkName: "components/image-slider" */).then(c => wrapFunctional(c.default || c))
export const InputRegion = () => import('../../components/inputRegion.vue' /* webpackChunkName: "components/input-region" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const Navigation = () => import('../../components/navigation.vue' /* webpackChunkName: "components/navigation" */).then(c => wrapFunctional(c.default || c))
export const ProfilePop = () => import('../../components/profilePop.vue' /* webpackChunkName: "components/profile-pop" */).then(c => wrapFunctional(c.default || c))
export const Qna = () => import('../../components/qna.vue' /* webpackChunkName: "components/qna" */).then(c => wrapFunctional(c.default || c))
export const Qnacomment = () => import('../../components/qnacomment.vue' /* webpackChunkName: "components/qnacomment" */).then(c => wrapFunctional(c.default || c))
export const QnacommentNavigation = () => import('../../components/qnacommentNavigation.vue' /* webpackChunkName: "components/qnacomment-navigation" */).then(c => wrapFunctional(c.default || c))
export const Qnacomments = () => import('../../components/qnacomments.vue' /* webpackChunkName: "components/qnacomments" */).then(c => wrapFunctional(c.default || c))
export const Quicks = () => import('../../components/quicks.vue' /* webpackChunkName: "components/quicks" */).then(c => wrapFunctional(c.default || c))
export const Reminder = () => import('../../components/reminder.vue' /* webpackChunkName: "components/reminder" */).then(c => wrapFunctional(c.default || c))
export const ScrapItems = () => import('../../components/scrapItems.vue' /* webpackChunkName: "components/scrap-items" */).then(c => wrapFunctional(c.default || c))
export const ScrapPop = () => import('../../components/scrapPop.vue' /* webpackChunkName: "components/scrap-pop" */).then(c => wrapFunctional(c.default || c))
export const ScrollLoading = () => import('../../components/scrollLoading.vue' /* webpackChunkName: "components/scroll-loading" */).then(c => wrapFunctional(c.default || c))
export const SimpleComment = () => import('../../components/simpleComment.vue' /* webpackChunkName: "components/simple-comment" */).then(c => wrapFunctional(c.default || c))
export const SimpleComments = () => import('../../components/simpleComments.vue' /* webpackChunkName: "components/simple-comments" */).then(c => wrapFunctional(c.default || c))
export const SmileGray = () => import('../../components/smileGray.vue' /* webpackChunkName: "components/smile-gray" */).then(c => wrapFunctional(c.default || c))
export const SmileGreen = () => import('../../components/smileGreen.vue' /* webpackChunkName: "components/smile-green" */).then(c => wrapFunctional(c.default || c))
export const SpamPop = () => import('../../components/spamPop.vue' /* webpackChunkName: "components/spam-pop" */).then(c => wrapFunctional(c.default || c))
export const SticyButton = () => import('../../components/sticyButton.vue' /* webpackChunkName: "components/sticy-button" */).then(c => wrapFunctional(c.default || c))
export const SwiperCarusel = () => import('../../components/swiperCarusel.vue' /* webpackChunkName: "components/swiper-carusel" */).then(c => wrapFunctional(c.default || c))
export const TriggerObserver = () => import('../../components/triggerObserver.vue' /* webpackChunkName: "components/trigger-observer" */).then(c => wrapFunctional(c.default || c))
export const AttendanceComments = () => import('../../components/attendanceComments/AttendanceComments.vue' /* webpackChunkName: "components/attendance-comments" */).then(c => wrapFunctional(c.default || c))
export const ChatAskSend = () => import('../../components/chat/askSend.vue' /* webpackChunkName: "components/chat-ask-send" */).then(c => wrapFunctional(c.default || c))
export const ChatDelete = () => import('../../components/chat/chatDelete.vue' /* webpackChunkName: "components/chat-delete" */).then(c => wrapFunctional(c.default || c))
export const ChatCloud = () => import('../../components/chat/cloud.vue' /* webpackChunkName: "components/chat-cloud" */).then(c => wrapFunctional(c.default || c))
export const ChatCreateHeader = () => import('../../components/chat/createHeader.vue' /* webpackChunkName: "components/chat-create-header" */).then(c => wrapFunctional(c.default || c))
export const ChatFooterTpye02 = () => import('../../components/chat/footerTpye02.vue' /* webpackChunkName: "components/chat-footer-tpye02" */).then(c => wrapFunctional(c.default || c))
export const ChatHeaderType02 = () => import('../../components/chat/headerType02.vue' /* webpackChunkName: "components/chat-header-type02" */).then(c => wrapFunctional(c.default || c))
export const ChatImageModal = () => import('../../components/chat/imageModal.vue' /* webpackChunkName: "components/chat-image-modal" */).then(c => wrapFunctional(c.default || c))
export const ChatLiveFooter = () => import('../../components/chat/liveFooter.vue' /* webpackChunkName: "components/chat-live-footer" */).then(c => wrapFunctional(c.default || c))
export const ChatLiveImageModal = () => import('../../components/chat/liveImageModal.vue' /* webpackChunkName: "components/chat-live-image-modal" */).then(c => wrapFunctional(c.default || c))
export const ChatLocation = () => import('../../components/chat/location.vue' /* webpackChunkName: "components/chat-location" */).then(c => wrapFunctional(c.default || c))
export const ChatLoding = () => import('../../components/chat/loding.vue' /* webpackChunkName: "components/chat-loding" */).then(c => wrapFunctional(c.default || c))
export const ChatMessagePopup = () => import('../../components/chat/messagePopup.vue' /* webpackChunkName: "components/chat-message-popup" */).then(c => wrapFunctional(c.default || c))
export const ChatSharePopup = () => import('../../components/chat/sharePopup.vue' /* webpackChunkName: "components/chat-share-popup" */).then(c => wrapFunctional(c.default || c))
export const CommentsComment = () => import('../../components/comments/comment.vue' /* webpackChunkName: "components/comments-comment" */).then(c => wrapFunctional(c.default || c))
export const ContactsEvaluateModal = () => import('../../components/contacts/EvaluateModal.vue' /* webpackChunkName: "components/contacts-evaluate-modal" */).then(c => wrapFunctional(c.default || c))
export const EventPostsPostComponent = () => import('../../components/eventPosts/PostComponent.vue' /* webpackChunkName: "components/event-posts-post-component" */).then(c => wrapFunctional(c.default || c))
export const EventPostsSectionPosts = () => import('../../components/eventPosts/SectionPosts.vue' /* webpackChunkName: "components/event-posts-section-posts" */).then(c => wrapFunctional(c.default || c))
export const FormInputResizeImg = () => import('../../components/form/InputResizeImg.vue' /* webpackChunkName: "components/form-input-resize-img" */).then(c => wrapFunctional(c.default || c))
export const FormLabel = () => import('../../components/form/Label.vue' /* webpackChunkName: "components/form-label" */).then(c => wrapFunctional(c.default || c))
export const FormInputAddress = () => import('../../components/form/inputAddress.vue' /* webpackChunkName: "components/form-input-address" */).then(c => wrapFunctional(c.default || c))
export const FormInputImgs = () => import('../../components/form/inputImgs.vue' /* webpackChunkName: "components/form-input-imgs" */).then(c => wrapFunctional(c.default || c))
export const FormProfileImg = () => import('../../components/form/profileImg.vue' /* webpackChunkName: "components/form-profile-img" */).then(c => wrapFunctional(c.default || c))
export const GameBricks = () => import('../../components/game/Bricks.vue' /* webpackChunkName: "components/game-bricks" */).then(c => wrapFunctional(c.default || c))
export const GameShooters = () => import('../../components/game/Shooters.vue' /* webpackChunkName: "components/game-shooters" */).then(c => wrapFunctional(c.default || c))
export const MainSectionActivity = () => import('../../components/main/SectionActivity.vue' /* webpackChunkName: "components/main-section-activity" */).then(c => wrapFunctional(c.default || c))
export const MainSectionAd = () => import('../../components/main/SectionAd.vue' /* webpackChunkName: "components/main-section-ad" */).then(c => wrapFunctional(c.default || c))
export const MainSectionCard = () => import('../../components/main/SectionCard.vue' /* webpackChunkName: "components/main-section-card" */).then(c => wrapFunctional(c.default || c))
export const MainSectionEvent = () => import('../../components/main/SectionEvent.vue' /* webpackChunkName: "components/main-section-event" */).then(c => wrapFunctional(c.default || c))
export const MainSectionFire = () => import('../../components/main/SectionFire.vue' /* webpackChunkName: "components/main-section-fire" */).then(c => wrapFunctional(c.default || c))
export const MainSectionLive = () => import('../../components/main/SectionLive.vue' /* webpackChunkName: "components/main-section-live" */).then(c => wrapFunctional(c.default || c))
export const MainSectionOpenChat = () => import('../../components/main/SectionOpenChat.vue' /* webpackChunkName: "components/main-section-open-chat" */).then(c => wrapFunctional(c.default || c))
export const MainSectionQuiz = () => import('../../components/main/SectionQuiz.vue' /* webpackChunkName: "components/main-section-quiz" */).then(c => wrapFunctional(c.default || c))
export const MainSectionStory = () => import('../../components/main/SectionStory.vue' /* webpackChunkName: "components/main-section-story" */).then(c => wrapFunctional(c.default || c))
export const MainSectionWebtoon = () => import('../../components/main/SectionWebtoon.vue' /* webpackChunkName: "components/main-section-webtoon" */).then(c => wrapFunctional(c.default || c))
export const MainSectionWebtoons = () => import('../../components/main/SectionWebtoons.vue' /* webpackChunkName: "components/main-section-webtoons" */).then(c => wrapFunctional(c.default || c))
export const MypageComment = () => import('../../components/mypage/comment.vue' /* webpackChunkName: "components/mypage-comment" */).then(c => wrapFunctional(c.default || c))
export const MypageName = () => import('../../components/mypage/name.vue' /* webpackChunkName: "components/mypage-name" */).then(c => wrapFunctional(c.default || c))
export const MypagePost = () => import('../../components/mypage/post.vue' /* webpackChunkName: "components/mypage-post" */).then(c => wrapFunctional(c.default || c))
export const NoticesNotice = () => import('../../components/notices/notice.vue' /* webpackChunkName: "components/notices-notice" */).then(c => wrapFunctional(c.default || c))
export const OnlineCongregationContent = () => import('../../components/onlineCongregation/Content.vue' /* webpackChunkName: "components/online-congregation-content" */).then(c => wrapFunctional(c.default || c))
export const OrganizationActivityChoiceModal = () => import('../../components/organization/ActivityChoiceModal.vue' /* webpackChunkName: "components/organization-activity-choice-modal" */).then(c => wrapFunctional(c.default || c))
export const OrganizationEdit = () => import('../../components/organization/Edit.vue' /* webpackChunkName: "components/organization-edit" */).then(c => wrapFunctional(c.default || c))
export const OrganizationInputList = () => import('../../components/organization/InputList.vue' /* webpackChunkName: "components/organization-input-list" */).then(c => wrapFunctional(c.default || c))
export const OrganizationNay = () => import('../../components/organization/Nay.vue' /* webpackChunkName: "components/organization-nay" */).then(c => wrapFunctional(c.default || c))
export const OrganizationSearchIcon = () => import('../../components/organization/SearchIcon.vue' /* webpackChunkName: "components/organization-search-icon" */).then(c => wrapFunctional(c.default || c))
export const OrganizationYea = () => import('../../components/organization/Yea.vue' /* webpackChunkName: "components/organization-yea" */).then(c => wrapFunctional(c.default || c))
export const PostsPostComponent = () => import('../../components/posts/PostComponent.vue' /* webpackChunkName: "components/posts-post-component" */).then(c => wrapFunctional(c.default || c))
export const PostsSectionNotices = () => import('../../components/posts/SectionNotices.vue' /* webpackChunkName: "components/posts-section-notices" */).then(c => wrapFunctional(c.default || c))
export const PostsSectionPosts = () => import('../../components/posts/SectionPosts.vue' /* webpackChunkName: "components/posts-section-posts" */).then(c => wrapFunctional(c.default || c))
export const PostsSectionRanking = () => import('../../components/posts/SectionRanking.vue' /* webpackChunkName: "components/posts-section-ranking" */).then(c => wrapFunctional(c.default || c))
export const PostsSectionSubs = () => import('../../components/posts/SectionSubs.vue' /* webpackChunkName: "components/posts-section-subs" */).then(c => wrapFunctional(c.default || c))
export const ResearchPollContent = () => import('../../components/researchPoll/Content.vue' /* webpackChunkName: "components/research-poll-content" */).then(c => wrapFunctional(c.default || c))
export const SomoimComments = () => import('../../components/somoim/comments.vue' /* webpackChunkName: "components/somoim-comments" */).then(c => wrapFunctional(c.default || c))
export const SomoimCommentNavigation = () => import('../../components/somoim/somoimCommentNavigation.vue' /* webpackChunkName: "components/somoim-comment-navigation" */).then(c => wrapFunctional(c.default || c))
export const SurveyCheckboxGroup = () => import('../../components/survey/CheckboxGroup.vue' /* webpackChunkName: "components/survey-checkbox-group" */).then(c => wrapFunctional(c.default || c))
export const SurveyDate = () => import('../../components/survey/Date.vue' /* webpackChunkName: "components/survey-date" */).then(c => wrapFunctional(c.default || c))
export const SurveyFile = () => import('../../components/survey/File.vue' /* webpackChunkName: "components/survey-file" */).then(c => wrapFunctional(c.default || c))
export const SurveyInputText = () => import('../../components/survey/InputText.vue' /* webpackChunkName: "components/survey-input-text" */).then(c => wrapFunctional(c.default || c))
export const SurveyRadioGroup = () => import('../../components/survey/RadioGroup.vue' /* webpackChunkName: "components/survey-radio-group" */).then(c => wrapFunctional(c.default || c))
export const SurveySelectOption = () => import('../../components/survey/SelectOption.vue' /* webpackChunkName: "components/survey-select-option" */).then(c => wrapFunctional(c.default || c))
export const SurveyItem = () => import('../../components/survey/SurveyItem.vue' /* webpackChunkName: "components/survey-item" */).then(c => wrapFunctional(c.default || c))
export const SurveyTextarea = () => import('../../components/survey/Textarea.vue' /* webpackChunkName: "components/survey-textarea" */).then(c => wrapFunctional(c.default || c))
export const VolunteerEdit = () => import('../../components/volunteer/Edit.vue' /* webpackChunkName: "components/volunteer-edit" */).then(c => wrapFunctional(c.default || c))
export const VolunteerList = () => import('../../components/volunteer/List.vue' /* webpackChunkName: "components/volunteer-list" */).then(c => wrapFunctional(c.default || c))
export const FormPostsInputCamera = () => import('../../components/form/posts/inputCamera.vue' /* webpackChunkName: "components/form-posts-input-camera" */).then(c => wrapFunctional(c.default || c))
export const FormPostsInputImg = () => import('../../components/form/posts/inputImg.vue' /* webpackChunkName: "components/form-posts-input-img" */).then(c => wrapFunctional(c.default || c))
export const FormPostsInputImg2 = () => import('../../components/form/posts/inputImg2.vue' /* webpackChunkName: "components/form-posts-input-img2" */).then(c => wrapFunctional(c.default || c))
export const FormPostsInputLink = () => import('../../components/form/posts/inputLink.vue' /* webpackChunkName: "components/form-posts-input-link" */).then(c => wrapFunctional(c.default || c))
export const FormPostsInputThumbnail = () => import('../../components/form/posts/inputThumbnail.vue' /* webpackChunkName: "components/form-posts-input-thumbnail" */).then(c => wrapFunctional(c.default || c))
export const FormPostsInputYoutube = () => import('../../components/form/posts/inputYoutube.vue' /* webpackChunkName: "components/form-posts-input-youtube" */).then(c => wrapFunctional(c.default || c))
export const MypageStaffsApprovals = () => import('../../components/mypage/staffs/approvals.vue' /* webpackChunkName: "components/mypage-staffs-approvals" */).then(c => wrapFunctional(c.default || c))
export const MypageStaffsStaffList = () => import('../../components/mypage/staffs/staffList.vue' /* webpackChunkName: "components/mypage-staffs-staff-list" */).then(c => wrapFunctional(c.default || c))
export const OrganizationBriefTeam = () => import('../../components/organization/brief/Team.vue' /* webpackChunkName: "components/organization-brief-team" */).then(c => wrapFunctional(c.default || c))
export const OrganizationChartList = () => import('../../components/organization/chart/ChartList.vue' /* webpackChunkName: "components/organization-chart-list" */).then(c => wrapFunctional(c.default || c))
export const OrganizationChartSelectTeam = () => import('../../components/organization/chart/SelectTeam.vue' /* webpackChunkName: "components/organization-chart-select-team" */).then(c => wrapFunctional(c.default || c))
export const OrganizationChartTeam = () => import('../../components/organization/chart/Team.vue' /* webpackChunkName: "components/organization-chart-team" */).then(c => wrapFunctional(c.default || c))
export const OrganizationManagePhase = () => import('../../components/organization/manage/Phase.vue' /* webpackChunkName: "components/organization-manage-phase" */).then(c => wrapFunctional(c.default || c))
export const OrganizationMyTownSearchGrid = () => import('../../components/organization/myTown/SearchGrid.vue' /* webpackChunkName: "components/organization-my-town-search-grid" */).then(c => wrapFunctional(c.default || c))
export const OrganizationRefInputCameraRef = () => import('../../components/organization/ref/InputCameraRef.vue' /* webpackChunkName: "components/organization-ref-input-camera-ref" */).then(c => wrapFunctional(c.default || c))
export const OrganizationStatisticItem = () => import('../../components/organization/statistic/Item.vue' /* webpackChunkName: "components/organization-statistic-item" */).then(c => wrapFunctional(c.default || c))
export const OrganizationManageApprovalList = () => import('../../components/organization/manage/approval/List.vue' /* webpackChunkName: "components/organization-manage-approval-list" */).then(c => wrapFunctional(c.default || c))
export const OrganizationManageApprovalRequestSearchList = () => import('../../components/organization/manage/approvalRequest/SearchList.vue' /* webpackChunkName: "components/organization-manage-approval-request-search-list" */).then(c => wrapFunctional(c.default || c))
export const OrganizationManageDistributeList = () => import('../../components/organization/manage/distribute/List.vue' /* webpackChunkName: "components/organization-manage-distribute-list" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
